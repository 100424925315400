<template>
  <div>
    <popup-modal ref="popup">
      <template>
        <v-btn style="right: 12px; top: -12px; float: left" fab dark small color="blue-grey" title="Закрыть окно" @click="$refs.popup.close()">
          <v-icon dark> mdi-close </v-icon>
        </v-btn>
        <span @click1="$refs.popup.close()" v-html="info"></span>
      </template>
    </popup-modal>
    <Widget
      v-if="loaded"
      ref="widget"
      :config="config"
      :height="height"
      @click="onClick($event)"
      :isLoading="loading"
      :chartOptions="chartOptions"
      :series="series"
      @loading="loading = $event"
      type="bar"
    >
      <template v-slot:title>Готовность по направлениям</template>
    </Widget>
  </div>
</template>

<script>
import { popupMenu } from "@/components/mixings";
export default {
  mixins: [popupMenu],
  components: {
    Widget: () => import("./widgets/chart"),
  },
  props: {
    height: { type: Number, default: 0 },
    config: { type: Object, default: () => {} },
  },
  data() {
    return {
      info: null,
      loaded: false,
      details: [],
      series: [],
      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          stackType: "100%",
          toolbar: {
            show: !true,
          },
          zoom: {
            enabled: !true,
          },
          events: {
            dataPointSelection: (event, chartContext, { seriesIndex, dataPointIndex, config, w }) => {
              console.log(w.config);
              const detail = w.config.xaxis.details;
              const cat = w.config.xaxis.categories[dataPointIndex];
              let res = `${cat} :`;
              detail
                .filter((d, i) => d.department == cat)
                .forEach((d, i) => {
                  if ((seriesIndex == i && d.type == 1) || (seriesIndex == w.config.series.length - 1 && d.type == 0)) {
                    res += (res ? "<br>" : "") + `${d.name}: ${d.value}`;
                  }
                });

              this.info = `<div style="max-height:300px; max-width: 300px" class="tooltip" style="margin:10px">${res}</div>`;
              this.$refs.popup.open();
              // Выводим дополнительную информацию
              //document.getElementById("info").innerText = `<div style="max-height:300px; max-width: 300px" class="tooltip" style="margin:10px">${res}</div>`;
            },
          },
        },
        stroke1: {
          width: 1,
          colors: ["#fff"],
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              total: {
                enabled: !true,
                offsetX: 0,
                style: {
                  fontSize: "13px",
                  fontWeight: 900,
                },
              },
            },
          },
        },
        dataLabels: {
          enabled: true,
          textAnchor: "middle",
          style: {
            colors: ["#fff"],
          },
          formatter: function (val, opt) {
            //   console.log(" opt.w.globals: ", opt, opt.w.globals.series);

            return opt.w.globals.series[opt.seriesIndex][opt.dataPointIndex];
          },
          offsetX: 0,
          dropShadow: {
            enabled: true,
          },
        },
        xaxis: {},
        yaxis: {},
        tooltip: {
          enabled: true,
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            const detail = w.config.xaxis.details;
            const cat = w.config.xaxis.categories[dataPointIndex];
            let res = `${cat} :`;
            //console.log(detail.filter((d, i) => d.department == cat));
            detail
              .filter((d, i) => d.department == cat)
              .forEach((d, i) => {
                if ((seriesIndex == i && d.type == 1) || (seriesIndex == series.length - 1 && d.type == 0)) {
                  res += (res ? "<br>" : "") + `${d.name}: ${d.value}`;
                }
              });
            return `<div style="max-height:300px; max-width: 300px" class="tooltip" style="margin:10px">${res}</div>`;
          },
        },
        legend: {
          show: false,
          position: "right",
          offsetY: 40,
        },
        fill: {
          opacity: 1,
        },
      },
    };
  },
  created() {
    this.fitchData();
  },
  computed: {
    loading: {
      get() {
        return this.isLoading;
      },
      set(v) {
        this.$emit("loading", v);
      },
    },
  },
  methods: {
    async fitchData() {
      let res = await this.$axios.get("/mechti/construction-schedule", {
        params: {
          filters: {
            object_id: 260,
            status: { condition: ">", value: "0" },
          },
          where: { status: { condition: ">", value: "0" } },
        },
      });
      //[281, 203, 204].includes(d.status)
      const s1 = { Стройка: [], Дизайнер: [], Снабжение: [0], Мебель: [], Комплектация: [] };
      const s0 = { Стройка: [], Дизайнер: [], Снабжение: [0], Мебель: [], Комплектация: [] };
      let series = [
        { name: "Стройка", data: [] },
        { name: "Дизайнер", data: [] },
        { name: "Снабжение", data: [] },
        { name: "Мебель", data: [] },
        { name: "Комплектация", data: [] },
      ];
      let data = res.data.data;
      res = await this.$axios.get("/mechti/construction-goods", {
        params: {
          filters: {
            object_id: 260,
            status: 0,
          },
        },
      });
      let dataB = res.data.data;
      let details = [];
      for (let i = 0; i < data.length; i++) {
        let el = data[i];
        if (el.status == 102) s1[el.department].push(el.work_days);
        else s0[el.department].push(el.work_days);
        details.push({ department: el.department, name: el.name, value: el.work_days, type: el.status == 102 ? 1 : 0 });
      }

      for (let i = 0; i < dataB.length; i++) {
        let el = dataB[i];
        if ([281, 203, 204].includes(el.tasks_status)) s1["Снабжение"][0]++;
        else s0["Снабжение"][0]++;
        details.push({ department: "Снабжение", name: el.name, value: el.amount, type: [281, 203, 204].includes(el.tasks_status) ? 1 : 0 });
      }
      let ss = [];

      let maxL1 = 0;
      for (const key in s1) {
        maxL1 = Math.max(maxL1, s1[key].length);
      }
      let nn = [];
      console.log("maxl", maxL1);
      for (let i = 0; i < maxL1; i++) {
        nn = [];
        for (let ii = 0; ii < series.length; ii++) {
          /// let n = s1[series[ii].name].reduce((accumulator, currentValue) => accumulator + currentValue, 0);
          nn.push(s1[series[ii].name]?.[i] || null);
        }
        ss.push({ name: "compleate" + i, data: nn, color: "#388E3C" });
      }
      nn = [];
      for (let i = 0; i < series.length; i++) {
        let n = s0[series[i].name].reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        nn.push(n);
      }
      ss.push({ name: "uncompleate", data: nn, color: "#EE6D7A" });
      console.log("214235235", ss);

      /*
      for (let i = 0; i < maxL1; i++) {
        let nn = [];
        for (let ii = 0; ii < series.length; ii++) {
          nn.push(s1[series[ii].name]?.[i] || null);
        }
        ss.push({ name: i + maxL2, data: nn, color: "#EE6D7A" });
      }*/
      this.series = ss;
      console.log("dddsfsf", this.series, this.chartOptions, ss);
      this.details = details;
      this.chartOptions.xaxis = {
        labels: {
          show: !true,
        },
        details: details,
        series1: ss,

        categories: series.map((s) => s.name),
      };

      this.chartOptions.yaxis = {
        show1: !false,
      };

      this.loaded = true;
    },
  },
};
</script>
